<!-- Finder particular de Usuarios Wweb -->

<template>
  <div class="usuwebF" v-if="loaded">

    <div class="conflex" style="justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:800px"> 
          
          <!-- Filtro de Busqueda -->          
          <div v-if="tipoVista" style="align-items:center">
            <div style="display:flex;width:60%">
              <v-select
                v-bind="$select"
                v-model="ct.tip[2]"
                :label="ct.tip[1]"
                :items="itemsTipos"                  
                item-value="id"
                item-text="name">            
              </v-select>

              <v-btn 
                v-bind="$cfe.btn.busca" 
                @click="eventFiltro('buscar')">
                  <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
              </v-btn> 
            </div>          

            <uiText  
              style="width:60%"
              v-model="ct.name[2]"
              :label="ct.name[1]"
              @Enter="eventFiltro('buscar')">                
            </uiText>
          </div>

          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>            

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>


      <!-- Mto Usuarios Web -->
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>
      
    </div>
  </div>
</template>


<script>

  import { mixF } from "@/mixins/mixF.js";  
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import usuwebM from "@/components/usuwebM";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, usuwebM },
    props: {},

    data() {
      return {
        stIni: {
          api: "usuwebF",
          titulo:"USUARIOS WEB", 
          name:"usuwebF",
          mView:'usuwebM',
          pView:[]
        },
       
        itemsTipos:[]
      };
    },


    computed: {
      tipoVista() {
        if (this.tipoView!= 'FA' && this.tipoView!= 'FL') return true;
        return false;
      }
    }, 


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[        
            { text: "Acciones", value: "acciones", sortable: false, width: "15%", slot:true },
            { text: "Usuario", value: "usu", width: "25%" },
            { text: "Nombre", value: "name", width: "65%" }           
          ]}
        ];

        this.headerGrid= this.headerSet();

        // items adicionales
        // zonas jefes de zona        
        this.itemsTipos= [
          { id: "0", name: "(Selecciona Tipo)" },
          { id: "1", name: "Funeraria" },
          { id: "5", name: "Funeraria Central" },
          { id: "6", name: "Grupo" },
          { id: "2", name: "Compañia" },
          { id: "4", name: "Compañia Central" },
          { id: "3", name: "Miscelanea" },
        ];

      },  


    }
  };
</script>
